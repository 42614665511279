
import Vue from "vue";
//@ts-ignore
import CardTextField from "@/components/Content/CardTextField.vue";
//@ts-ignore
import CardAutocomplete from "@/components/Content/CardAutocomplete.vue";
//@ts-ignore
import { getError } from "@/utils/resolveObjectArray";
//@ts-ignore
import CardSwitch from "@/components/Content/CardSwitch.vue";
import {
	isRequired,
	isNumber,
	isMax,
	isMaxCustomLength,
	isInteger,
	//@ts-ignore
} from "@/services/rule-services";
//@ts-ignore
import TableTerms from "./Terms/List.vue";
//@ts-ignore
import TableTermDelivery from "@/views/Admin/Modifiers/List/TableDelivery.vue";
//@ts-ignore
import PanelOptions from "./Terms/PanelOptions.vue";
//@ts-ignore
import { initModifier } from "@/utils/initData";
import { isNull, isUndefined, some, sortBy, isEmpty, isEqual } from "lodash";
import { mapActions, mapGetters } from "vuex";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
// @ts-ignore
import { prepareDataDelivery, prepareTableHeaders, prepareTableHeadersDelivery } from "@/views/Admin/Modifiers/Logic/utils";
//@ts-ignore
import { getPermisionModifiers } from "@/utils/permissionResolve.ts";

const BID_MODIFIER_TYPE = 110; //Bid Modifier
const DELIVERY_MODIFIER_TYPE = 111; //Delivery Modifier
const MAX_MODIFIER_VALUE = 10; //Max Modifier value
const MIN_MODIFIER_VALUE = 0; //Min Modifier value
/**
 * Lista de "extras" habilitados para módulos de bid modifier
 */
const ENABLED_BID_MODULES = [
	"geo",
	"environment",
	"platform",
	"video",
	"content",
	"time",
	"exchange",
	"user",
	"app_site"
];

/**
 * Lista de "extras" habilitados para módulos de delivery modifier
 */
 const ENABLED_DELIVERY_MODULES = [
	"app_site",
	"environment",
	"video",
	"content",
	"exchange",
];

export default Vue.extend({
	name: "OverviewModifier",
	props: {
		entity: {
			type: Object,
			default: function () {
				return initModifier();
			},
		},
		advertisers: {
			type: Array,
			required: true,
		},
		types: {
			type: Array,
			required: true,
		},
		modules: {
			type: Array,
			required: true,
		},
		associationMode: {
			type: Boolean,
			default: function () {
				return false;
			},
		},
		advertiserSelectDisabled: {
			type: Boolean,
			default: function () {
				return false;
			},
		},
		options: {
			type: Object,
			default: function() {
				return {};
			}
		},
		deliveryOptions: {
			type: Array,
			default: function() {
				return [];
			},
		},
		termDelivery: {
			type: Array,
			default: function() {
				return [];
			},
		},
		disabledModifier: { type: Boolean, default: false },
	},
	components: {
		CardTextField,
		CardAutocomplete,
		CardSwitch,
		TableTerms,
		TableTermDelivery,
		PanelOptions,
	},
	data: () => ({
		valid: false,
		showDefaultWeight: false,
		selectedModule: undefined,
		showTermExistMessage: false,
		showTermRequiredMessage: false,
		showMaxTermMessage: false,
		nameRules: [],
		alternativeRules: [],
		advertiserRules: [],
		modifierTypeRules: [],
		bidModels: [],
		fallback_weight: "1"
	}),
	created() {},
	async mounted() {
		this.setLoadingData(TypeLoading.loading)
		await this.dispatchMatchingTypes();
		await this.loadData();
		await this.setLoadingData();
		//await this.fetchBidModels();
		this.fallback_weight = `${this.entity.fallback_weight}` || "1";
	},
	computed: {
		...mapGetters("profile", ["getAbility"]),
		/**
		 * GET
		 */
		getAdvertisers(): any[] {
			return this.advertisers;
		},

		getModules(): any[] {
			if(this.isDeliveryModifierTypeSelected){
				return sortBy(this.getEnabledModules(ENABLED_DELIVERY_MODULES, this.modules), ['value']);
			};
			return sortBy(this.getEnabledModules(ENABLED_BID_MODULES, this.modules), ['value']);
		},

		getDeliveryModifiersType(){
			return this.deliveryOptions;
		},

		getTypes(): any[] {
			return this.types;
		},

		getEntity: {
			get() {
				return this.entity;
			},
			set(val) {
				this.$emit("input", val);
			},
		},

		getErrors() {
			return this.$store.state.proccess.errors;
		},

		getRules() {
			return {
				isRequired,
				isNumber,
				isMaxCustomLength,
				isInteger,
			};
		},

		getBidModels(){
			return this.bidModels;
		},

		isDisabledModifiers(){
			return this.isEdit || this.disabledModifier;
		},

		// Terms
		prepareTableHeaders() {
			return [
				{
					text: "Module",
					align: "start",
					sortable: false,
					filterable: false,
					value: "module_name",
				},
				{
					text: "Key",
					align: "start",
					sortable: false,
					filterable: false,
					value: "key",
				},
				{
					text: "Type",
					align: "start",
					sortable: false,
					filterable: false,
					value: "matching_type_name",
				},
				{
					text: "Value",
					align: "center",
					sortable: false,
					filterable: false,
					value: "value_name",
				},
				{
					text: "Multiplier",
					align: "center",
					sortable: false,
					filterable: false,
					value: "modifier",
					width: "125px",
				},
				{
					text: "Dinamic",
					align: "center",
					sortable: false,
					filterable: false,
					value: "override_multiplier",
					width: "125px",
				},
				{
					text: "",
					align: "center",
					sortable: false,
					filterable: false,
					value: "actions",
				},
			];
		},

		getTerms(): any[] {
			let matchings = this.getMatchingTypes;
			//let modules = this.getModules;
			this.showTermRequiredMessage = false;
			if (!this.entity || !this.entity.terms) {
      				  return [];
    		}
			return this.entity?.terms.map((e) => {
				return {
					...e,
					matching_type_name: e?.matching_type_name
						? e.matching_type_name
						: matchings.find((mat) => {
								return mat.id == e.matching_type_id;
						  })?.value,
					module_name: e?.module_name
						? e.module_name
						: this.modules.find((mod) => {
								return mod.id == e.module_id;
						  })?.value,
				};
			});
		},

		getTermsDelivery(){
			this.showTermRequiredMessage = false;
			return this.termDelivery;
		},

		getItemTableDelivery(){
			if(isEmpty(this.getTermsDelivery)) return [];
			return this.getTermsDelivery;
		},

		isDeliveryModifierTypeSelected() {
			let result = this.entity.modifier_type_id == DELIVERY_MODIFIER_TYPE;
			if (!result) {
				this.getEntity.default_weight = undefined;
			}
			return result;
		},

		isBidModifierTypeSelected() {
			return this.entity.modifier_type_id == BID_MODIFIER_TYPE;
		},

		getSelectedModule() {
			this.showTermRequiredMessage = false;
			return this.selectedModule;
		},

		getDefaultPanelSize() {
			let defSize = { sm: "12", md: "4", lg: "4" };
			if (this.getSelectedModule == "TIME") {
				return { sm: "12", md: "12", lg: "12" };
			}
			return defSize;
		},

		getDefaultTableSize() {
			let defSize = { sm: "12", md: "8", lg: "8" };
			if (this.getSelectedModule == "TIME") {
				return { sm: "12", md: "12", lg: "12" };
			}
			return defSize;
		},

		isEdit() {
			return !isNaN(Number(this.getEntity?.id));
		},

		getMatchingTypes(): any[] {
			return this.$store.state.modifier_options.matching_types;
		},

		getTableHeaders(){
			if(this.isDeliveryModifierTypeSelected){
				if(isEmpty(this.getTermsDelivery)){
					return prepareTableHeadersDelivery([]);
				}else{
					let values = this.getTermsDelivery.map((t) => t.targeting);
					let flattened = values.flatMap(val => val);
					return prepareTableHeadersDelivery(flattened);
				}
			}else{
				return prepareTableHeaders();
			};
		},

		canCreate(){
			if(this.isEdit){
				return this.canEdit;
			}
			if(this.isDeliveryModifierTypeSelected){
				return this.getAbility.can(this.getPermission.subComponent.delivery_modifier
										.create_delivery, this.getPermission.subject);
			}
			if(this.isBidModifierTypeSelected){
				return this.getAbility.can(this.getPermission.subComponent.bid_modifier
											.create_bid, this.getPermission.subject);
			}
			return false;
		},

		canEdit(){
			if(this.isDeliveryModifierTypeSelected){
				return this.getAbility.can(this.getPermission.subComponent.delivery_modifier
										.update_delivery, this.getPermission.subject);
			}
			if(this.isBidModifierTypeSelected){
				return this.getAbility.can(this.getPermission.subComponent.bid_modifier
											.update_bid, this.getPermission.subject);
			}
			return false;
		},

		getPermission(){
			return getPermisionModifiers();
		}
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("proccess", ["setLoadingField"]),
		...mapActions("models", ["bidModelsList"]),
		/**
		 * Función que devuelve los modulos para cada tipo de modifier
		 *
		 * @param items Lista de "extras" disponibles
		 * @param modules Lista de los modules
		 * @return
		 */
		 getEnabledModules(items: string[], modules): any[] {
			if(modules instanceof Array) {
				return modules.filter(m => items.some(item => m.extra == item));
			}

			return [];
		},

		async loadData(){
			if(this.disabledModifier){
				this.getEntity.modifier_type_id = 110;
			}
		},

		handlerClearTermDelivery(){
			this.termDelivery = [];
		},

		handleChangeFallback(value) {
			this.fallback_weight = value;
		},

		getError(index: string) {
			return getError(this.getErrors, index);
		},

		async addRules() {
			this.nameRules = [this.getRules.isRequired];
			this.alternativeRules = [this.getRules.isInteger];
			this.advertiserRules = [this.getRules.isRequired, this.getRules.isNumber];
			/*
			if(this.isDeliveryModifierTypeSelected) {
				this.advertiserRules = [this.getRules.isRequired, this.getRules.isNumber];
			}*/

			this.modifierTypeRules = [
				this.getRules.isRequired,
				this.getRules.isNumber,
			];
		},

		async clearRules() {
			this.nameRules = [];
			this.advertiserRules = [];
			this.modifierTypeRules = [];
			this.alternativeRules = [];
		},

		handleChangeModifierType(){
			this.selectedModule = undefined;
			this.getEntity.module_id = undefined;
			this.getEntity.delivery_modifiers_option = this.getEntity.modifier_type_id == 111 ? 101 : undefined;
		},

		async validate() {
			let form = this.$refs.form;
			const valid = await form.validate();
			return await valid;
		},

		async validateTerms() {
			let flag = true;
			let entities = this.getTerms;
			if (isNull(entities) || isUndefined(entities)) {
				return flag;
			}
			if (entities.length == 0) {
				this.showTermRequiredMessage = true;
				return false;
			}
			this.showTermRequiredMessage = false;
			let i = 0;
			while (i < entities.length && flag) {
				if (isMax(entities[i]?.modifier, MAX_MODIFIER_VALUE) !== true) {
					flag = false;
				}
				i++;
			}
			return flag;
		},

		async validateTermsDelivery() {
			let flag = true;
			let entities = this.getTermsDelivery;
			if (isNull(entities) || isUndefined(entities)) {
				return flag;
			}
			if (entities.length == 0) {
				this.showTermRequiredMessage = true;
				return false;
			}
			this.showTermRequiredMessage = false;
			let i = 0;
			entities.forEach(entity => {
				if(!flag) return;

				if(entity.targeting.length > 3 || entity.targeting.length < 1) {
					flag = false
				}
				if(entity.weight < 0 || entity.weight > 100) flag = false

				i++
			})
			return flag;
		},

		async fetchBidModels(){
			await this.setLoadingField(TypeLoading.loading);
			await this.bidModelsList()
			.then(async(res) => {
				this.bidModels = res;
				await this.setLoadingField();
			})
			.catch(async(err) => {
				await this.setLoadingField();
			});
		},

		handleCancel() {
			this.$router.push({ name: "ModifiersIndex" });
		},

		async handleSubmit() {
			if(this.isDeliveryModifierTypeSelected){
				await this.handleSubmitDelivery();
				return;
			}
			await this.handleSubmitBidModifier();
		},

		async handleSubmitDelivery(){
			try{
				this.showMaxTermMessage = false;
				await this.addRules();
				if (!(await this.validate())) return;
				if (!(await this.validateTermsDelivery())) return;
				const entity = {
					...this.getEntity,
					fallback_weight: parseFloat(this.fallback_weight),
					terms: this.getTermsDelivery,
				};

				if (!this.associationMode) {
					this.$emit(this.isEdit ? "update-entity" : "create-entity", {
						entity: prepareDataDelivery(entity),
						isDeliveryModifier: this.isDeliveryModifierTypeSelected
					});
				} else {
					this.$emit("create-modifier", {
						entity: prepareDataDelivery(entity),
						isDeliveryModifier: this.isDeliveryModifierTypeSelected,
						executeClearTermFunction: () => {this.handlerClearTermDelivery();},
					});
					this.selectedModule = undefined;
				}
				await this.clearRules();
			}catch (error) {
				console.error("handleSubmit", { error: error });
			}
		},

		async handleSubmitBidModifier(){
			try {
				await this.addRules();
				if (!(await this.validate())) return;
				if (!(await this.validateTerms())) return;
				const entity = {
					...this.getEntity,
					terms: this.getTerms,
				};
				if (!this.associationMode) {
					this.$emit(this.isEdit ? "update-entity" : "create-entity", {
						entity: entity,
						isBidModifier: entity.modifier_type_id == BID_MODIFIER_TYPE,
					});
				} else {
					this.$emit("create-modifier", {
						entity: entity,
						isBidModifier: this.getEntity.modifier_type_id == BID_MODIFIER_TYPE,
					});
					this.selectedModule = undefined;
				}
				await this.clearRules();
			} catch (error) {
				await this.clearRules();
				console.error("handleSubmit", { error: error });
			}
		},

		handleChangeModule() {
			this.selectedModule = this.getSelectedValueById(
				this.getModules,
				this.entity.module_id
			);
			this.showTermRequiredMessage = false;
		},

		getSelectedValueById(data: any, id: Number) {
			if (data) {
				let result = data.find((e) => e.id == id);
				return result ? result.value.toUpperCase() : undefined;
			}
			return undefined;
		},

		async handleAddTerm(item: any) {
			if(this.isDeliveryModifierTypeSelected){
				this.showMaxTermMessage = false;
				await this.handleAddTermDelivery(item);
				return;
			}
			if(item.key == "interstitial"){
				let itemIns = this.getTerms.find(i => i.module_name == item.module_name && i.key == item.key);
				if(!isUndefined(itemIns)){
					this.handleRemoveTermIntersticial(itemIns);
				}
				let elements = this.getTerms;
				elements.push(item);
				this.entity.terms = elements;
			}else{
				this.showTermExistMessage = await this.verifyExistingTerm(item);
				if (!this.showTermExistMessage) {
					let elements = this.getTerms;
					elements.push(item);
					this.entity.terms = elements;
				}
			}
		},

		async handleAddTermDelivery(item: any){
			this.showMaxTermMessage = false;
			// If termsDelivery is empty, creates a new one
			if(isEmpty(this.getTermsDelivery)) {
				let obj = {
					targeting: [{
						key: item.key,
						module_name: item.module_name,
						value: item.value,
						value_name: item.value_name
					}],
					rank: this.getTermsDelivery.length + 1,
					weight: 1
				}
				this.getTermsDelivery.push(obj);
			}
			// If there is at least one
			else {
				// Find if the selected target already exists on the targeting
				const termExist = this.getTermsDelivery[0].targeting.find(target => target.key == item.key);
				if(termExist) {
					let obj = {
						targeting: [{
							key: item.key,
							module_name: item.module_name,
							value: item.value,
							value_name: item.value_name
						}],
						rank: this.getTermsDelivery.length + 1,
						weight: 1
					}
					this.getTermsDelivery.push(obj);
				}
				// Else we push this new target on the first, and automatically set Any values on other fields
				// (View the watcher termDelivery)
				else {
					if(this.getTermsDelivery[0].targeting.length > 2){
						this.showMaxTermMessage = true;
						return;
					}
					this.getTermsDelivery[0].targeting.push(item)
					this.updateTermDelivery(this.getTermsDelivery)
				}
			}
		},

		handleRemoveTermTime(item: any) {
			this.entity.terms = this.getTerms.filter((m) => {
				return m.value != item;
			});
		},

		async handleDeleteDeliveryColumn(key: string){
			this.getTermsDelivery.forEach(term => {
				const newTargetting = term.targeting.filter(target => target.key != key)
				term.targeting = newTargetting
			});

			if(this.getTermsDelivery.length > 0){
				if(this.getTermsDelivery[0].targeting.length == 0){
					this.$emit("delete-terms");
				}
			}
		},

		async handleDeleteDeliveryItem(item){
			const index = this.getTermsDelivery.findIndex(term => term.rank === item.rank)

			this.getTermsDelivery.splice(index, 1)

			this.getTermsDelivery.forEach(term => {
				if(term.rank > item.rank) {
					term.rank--
				}
			})
		},

		handleRemoveTermIntersticial(item: any) {
			this.entity.terms = this.getTerms.filter((m) => {
				return m.value != item.value;
			});
		},

		async verifyExistingTerm(item: { module_name: any; key: any; value: any }) {
			const terms: Array<any> = this.getTerms;
			return some(terms, { 'module_name':	item.module_name, 'key': item.key,	'value':item.value });
		},

		areEquals(prev: any, next: any) {
			if (isUndefined(prev) || isNull(prev)) {
				return false;
			}
			if (isUndefined(next) || isNull(next)) {
				return false;
			}
			if (
				prev.module_name.toUpperCase() == next.module_name.toUpperCase() &&
				prev.key.toUpperCase() == next.key.toUpperCase() &&
				prev.value.toUpperCase() == next.value.toUpperCase()
			) {
				return true;
			}
			return false;
		},

		// isEnableModule(module: string) {
		// 	if (isUndefined(module) || isNull(module)) {
		// 		return false;
		// 	}
		// 	let entity = ENABLE_MODULES.find((e: String) => {
		// 		return e.toUpperCase() == module.toUpperCase();
		// 	});
		// 	if (isUndefined(entity) || isNull(entity)) {
		// 		return false;
		// 	}
		// 	return true;
		// },

		handleDeleteTerm(item: any) {
			if (this.getSelectedModule == "TIME"){
				this.$refs.pnlOption.removeTermTime(item);
				this.handleRemoveTermTime(item.value);
			}else{
				this.entity.terms = this.getTerms.filter((e) => {
					return e != item;
				});
			}
		},

		handleGetTerm(term_name: any) {
			let filterEntitys = this.entity.terms.filter((t) => t.key === term_name);
			this.$refs.pnlOption.setTerm(filterEntitys, term_name);
		},

		async dispatchMatchingTypes() {
			return this.$store.dispatch("modifier_options/getMatchingTypes", {
				root: true,
			});
		},

		updateTermDelivery(value) {
			const values = value.map((t) => t.targeting);
			const flattened = values.flatMap(val => val);
			const columns = flattened.reduce((saved, actual) => {
				if(saved.indexOf(actual.key) === -1) {
					return [...saved, actual.key]
				}

				return saved
			}, [])

			columns.forEach(column => {
				value.forEach(term => {
					const actualTerm = term.targeting.find(target => target.key == column);

					if(!actualTerm) {
						term.targeting.push({
							key: column,
							value: null
						})
					}
				})
			})
		},

		async checkTerms(){
			if(isEmpty(this.termDelivery)){
				this.fallback_weight = "1";
			}
		}
	},
	watch: {
		termDelivery: function (value) {
			this.updateTermDelivery(value);
			this.checkTerms();
		},
		"entity.fallback_weight"(val) {
			this.fallback_weight = `${val}` || "1";
		}
	},
});
